import { useMemo } from 'react'
import { getRandomGradient, getRandomGradientDirection } from '../utils/helpers.js'

const linkClass =
  'text-lg text-blue-300 underline cursor-pointer hover:text-orange-500 transition-colors duration-500 p-8'

export default function Tip() {
  const randomGradient = useMemo(() => getRandomGradient(true), [])
  const randomGradientDirection = useMemo(getRandomGradientDirection, [])

  return (
    <div
      className={`flex flex-col text-center items-center justify-center min-h-screen pt-10 pb-10 font-inter ${randomGradientDirection} ${randomGradient} bg-opacity-80`}
    >
      <h1 className="text-2xl text-white font-bold">💖 Thank you! 💖</h1>
      <div className="flex flex-col md:flex-row">
        <a href="https://cash.app/$KyleAnne" target="_blank" className={linkClass} rel="noopener noreferrer">
          <img alt="QR code for CashApp" src="/explodedsoda-cashapp-transparent-QR.png" className="h-72 w-72" />
          <div>$KyleAnne</div>
        </a>
        <a href="https://venmo.com/u/explodedsoda" target="_blank" className={linkClass} rel="noopener noreferrer">
          <img alt="QR code for Venmo" src="/explodedsoda-venmo-transparent-QR.png" className="h-72 w-72" />
          <div>@explodedsoda</div>
        </a>
      </div>
    </div>
  )
}
