import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './index.css'
import App from './App'
import Tip from './tip'
import reportWebVitals from './reportWebVitals'
import { initGA } from './utils/analytics.js'

const root = ReactDOM.createRoot(document.getElementById('root'))

if (process.env.NODE_ENV === 'production') {
  initGA()
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/tip" element={<Tip />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
)

reportWebVitals()
